<script>
import { mapGetters } from 'vuex'
import IconRevoked from '../../assets/icon-revoked.vue'
import IconValidated from '../../assets/icon-validated.vue'
// import IconLink from '../../assets/icon-link.vue'

import ShowCredentialImages from '../../components/ShowCredentialImages/ShowCredentialImages.vue'

import { GET_USER } from '../../store/actions'
import { CredentialStatus } from '../../consts/CredentialStatus'

export default {
  name: 'Verify',
  components: {
    // IconLink,
    IconRevoked,
    IconValidated,
    ShowCredentialImages,
  },

  data() {
    return {
      proof: null,
      stamp: false,
      slide: false,
      isValid: false,
      frontTemplate: [],
      backTemplate: null,
      frontend_props: null,
      userData: {},
      WAITING_WALLET: CredentialStatus.WAITING_WALLET,
      PENDING: CredentialStatus.PENDING_APPROVAL,
      REVOKED: CredentialStatus.REVOKED,
      ACTIVE: CredentialStatus.APPROVED,
      statusVerify: null,
      pdf_url: null,
      A4ratio: 1.4142,
      imgArray: [],
    }
  },
  head() {
    return {
      title: `${this.name || ''} Verify`,
      meta: [
        {
          name: 'description',
          content: `Connect and follow ${this.name}`,
        },
        { property: 'og:title', content: `${this.name} - Verify` },
        { property: 'og:site_name', content: 'Verify' },
        {
          property: 'og:description',
          content: `Connect and follow ${this.name}`,
        },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:url',
          content:
            `https://mycredentials.wallid.io/ViewCredential/${
            this.userData._id}`,
        },
        {
          property: 'og:image',
          content: this.imgArray[0],
        },
      ],
    }
  },
  computed: {
    getProofShare() {
      const linkList
        = this.userData.proof_shares && this.userData.proof_shares.slice(1)
      return linkList
    },
    ...mapGetters(['isLoading']),
  },

  created() {},
  async mounted() {
    this.debug(`*** ${this.$options.name} ***`)

    this.proof = this.$route.params.assetId
    this.debug('query proof', this.$route.params)
    const assetData = await this.asyncData({ assetId: this.proof })
    this.debug('App Verify: assetData ', assetData)

    Object.keys(assetData).forEach((key) => {
      this[key] = assetData[key]
    })

    this.debug(this.name)

    // if (!this.name) { this.getVerifyDataMapped() }
    if (this.name) {
      setTimeout(() => {
        this.$store.commit('isLoading', false)
        this.startAnimation()
      }, 25 * 100)
    }
    // if (this.statusVerify !== this.PENDING) { this.verifyData(this.userData) }
  },
  methods: {

    findName(userData) {
      if (!userData) {
        throw new Error('No user data')
      }

      let nameIndex = Object.keys(userData).find(
        i =>
          i.toLowerCase() === 'name'
          || i.toLowerCase() === 'nome'
          || i.toLowerCase() === 'nombre',
      )
      if (!nameIndex && userData.front) {
        nameIndex = userData.front.find(
          i =>
            i.attr.toLowerCase() === 'name'
            || i.attr.toLowerCase() === 'nome'
            || i.attr.toLowerCase() === 'nombre',
        )
      }
      const name = (nameIndex && nameIndex.value) || userData[nameIndex]
      console.log('name', name)

      return name
    },
    async asyncData({ assetId }) {
      if (assetId) {
      // fetch data from API
        try {
          const { data: res } = await this.$store.dispatch(GET_USER, { id: assetId })

          const pdf_url = res.pdf_url
          const name = this.findName(res.user_data)
          const frontend_props = res.frontend_props
          const customTemplateName = res.frontend_props.customTemplateName
          const photoURL = res.ca_photo
          const imgArray = res.imgArray

          let frontTemplate = []
          if (res.user_data.front && Array.isArray(res.user_data.front)) {
            frontTemplate = [...res.user_data.front]
          }
          else {
            const frontTemplateObj = JSON.parse(JSON.stringify(res.user_data))
            delete frontTemplateObj.tables

            if (res.template_itens) {
              for (let index = 0; index < res.template_itens.length; index++) {
                const el = res.template_itens[index]
                if (frontTemplateObj[el.attr] && el.order > -1) {
                  frontTemplate[el.order] = {
                    attr: el.attr,
                    value: frontTemplateObj[el.attr],
                  }
                }
                else if (frontTemplateObj[el.attr]) {
                  frontTemplate.push({
                    attr: el.attr,
                    value: frontTemplateObj[el.attr],
                  })
                }
              }
            }
            else {
              for (const [key, value] of Object.entries(frontTemplateObj)) {
                frontTemplate.push({ attr: key, value }) // "a 5", "b 7", "c 9"
              }
            }
          }
          let backTemplate
          if (res.user_data.tables || res.user_data.table) {
            backTemplate = JSON.parse(
              JSON.stringify(res.user_data.tables || res.user_data.table),
            )
          }
          const statusVerify = res.status
          return {
            userData: res,
            pdf_url,
            name,
            frontend_props,
            customTemplateName,
            frontTemplate,
            backTemplate,
            statusVerify,
            photoURL,
            imgArray,
          }
        }
        catch (error) {
          console.error(error)
        // Redirect to error page or 404 depending on server response
        }
      }
    },
    startAnimation() {
      this.stamp = true
      setTimeout(() => {
        this.moveToSide()
        this.debug('callback one')
      }, 1 * 1000)
    },
    moveToSide() {
      this.debug('callback two')
      this.slide = true
    },

    // verifyData(userData) {
    //   if (userData.verify) {
    //     const verify = {
    //       contract_address: userData.ca_contract_address,
    //       credential_sod: userData.verify.credential_sod,
    //       tid_sod: userData.verify.tid_sod,
    //       template_sod: userData.verify.template_sod,
    //       credential_sig: userData.credential_sig,
    //     }

    //     this.$store.dispatch(VERIFY, verify).then((res) => {
    //       this.debug('res.chainData', res.chainData)

    //       this.isValid = res.chainData.result
    //       this.startAnimation()
    //     })
    //   }
    // },

    // async getVerifyDataMapped() {
    //   let data = {}
    //   try {
    //     data = await this.$store.dispatch(GET_USER_DATA, this.proof)

    //     Object.keys(data).forEach((key) => {
    //       this[key] = data[key]
    //     })
    //   }
    //   catch (error) {
    //     console.error(error)
    //     // Redirect to error page or 404 depending on server response
    //   }

    //   if (this.statusVerify !== this.PENDING) { this.verifyData(this.userData) }

    //   this.$store.commit('setIsLoading', false)

    //   this.startAnimation()
    // },
  },

}
</script>

<template>
  <div class="verify">
    <v-container class="pt-md-10 pa-0 pa-md-3">
      <v-row justify="center">
        <v-col cols="12" class="pl-9 grey-background">
          <div class="d-flex align-center justify-center">
            <v-img
              alt="Wallid Logo"
              class="shrink mr-1"
              contain
              src="../../assets/logo-credential-issuers.png"
              transition="scale-transition"
              width="130"
            />
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" class="pt-md-6" :class="{ slide }">
        <v-col cols="12" md="6" class="header">
          <h1 class="title_header">
            {{
              $t('verify.title', {
                name: name || $t('verify.fillName'),
                credName: userData.template_name,
                caName: userData.ca_name,
              })
            }}
          </h1>
        </v-col>
        <v-col cols="12" md="12" class="pb-16 pt-md-10">
          <v-card class="back-card py-2" :class="{ slide }">
            <v-container class="">
              <v-row justify="space-between">
                <v-col
                  v-if="customTemplateName === 'templateEditor'"
                  cols="12"
                  md="4"
                  class="pa-0 pa-md-3 pt-md-5"
                >
                  <ShowCredentialImages
                    class="id-card"
                    :img-array="imgArray"
                    :current-layout="frontend_props.currentLayout"
                    :width="530"
                    :height="374"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="8"
                  class="more-info text-center pl-md-10 mt-10 mt-md-0"
                >
                  <v-card flat>
                    <IconValidated
                      v-if="[PENDING, ACTIVE, WAITING_WALLET].includes(statusVerify)"
                      class="over-top"
                    />
                    <IconRevoked
                      v-else-if="!isValid && statusVerify === REVOKED"
                      class="over-top"
                    />
                    <!-- <p
                      v-if="statusVerify === PENDING"
                      class="state mb-1 mt-5"
                      style="font-size: 14px"
                    >
                      {{
                        $t('verify.pending[0]', {
                          credName: userData.template_name,
                        })
                      }}
                      <span v-if="name" style="font-weight: 600">
                        {{ $t('verify.pending[1]', { name }) }}
                      </span>
                      {{
                        $t('verify.pending[2]', { caName: userData.ca_name })
                      }}
                    </p> -->

                    <p
                      v-if="!isValid && statusVerify === REVOKED"
                      class="state revoked mb-1 mt-5"
                      style="font-size: 14px"
                    >
                      {{
                        $t('verify.revoked', {
                          credName: userData.template_name,
                          caName: userData.ca_name,
                          expDate: userData.exp_date,
                        })
                      }}
                    </p>

                    <p
                      v-if=" [PENDING, ACTIVE, WAITING_WALLET].includes(statusVerify)"
                      class="state mb-1 mt-5"
                      style="font-size: 14px"
                    >
                      {{
                        $t('verify.valid[0]', {
                          credName: userData.template_name,
                        })
                      }}
                      <span v-if="name" style="font-weight: 600">
                        {{ $t('verify.valid[1]', { name }) }}
                      </span>
                      {{ $t('verify.valid[2]', { caName: userData.ca_name }) }}.
                    </p>

                    <v-divider class="my-3" />

                    <!-- <p class="state my-2" style="font-size: 14px">
                      {{ $t('verify.more_info.title') }}
                    </p>
                    <p class="state my-1">
                      {{ $t('verify.more_info.smart_contract') }}

                      <span>{{ userData.ca_contract_address }} </span>
                    </p> -->
                    <p class="state my-1">
                      {{ $t('verify.more_info.template_id') }}
                      <span>{{ userData.tid }} </span>
                    </p>
                    <p v-if="userData.verify" class="state my-1">
                      {{ $t('verify.more_info.data_hash') }}
                      <span>
                        {{ userData.verify.credential_sod }}
                      </span>
                    </p>
                    <p v-if="userData.credential_sig" class="state my-1">
                      {{ $t('verify.more_info.signature') }}
                      <span>
                        {{ userData.credential_sig }}
                      </span>
                    </p>
                    <!-- <a
                      v-if="statusVerify !== PENDING"
                      class="state my-2"
                      :class="statusVerify !== PENDING ? '' : 'disable-link'"
                      target="_blank"
                      :href="blockURL"
                    >
                      <v-icon class="status-verify">mdi-checkbox-blank-circle</v-icon>
                      {{ $t('verify.more_info.verify') }}

                      <IconLink class="" />
                    </a> -->

                    <!-- <a
                      v-if="statusVerify === PENDING"
                      class="state my-2"
                      :class="statusVerify !== PENDING ? '' : 'disable-link'"
                      target="_blank"
                      :href="blockURL"
                    >
                      <v-icon class="status-verify">mdi-checkbox-blank-circle</v-icon>
                      {{ $t('verify.more_info.blockchain') }}
                    </a> -->

                    <v-divider class="my-3" />
                    <p class="state my-1">
                      {{ $t('verify.more_info.check_owner') }}
                    </p>
                    <p class="state my-1">
                      {{ name }}
                    </p>
                    <!-- <a
                      v-for="(link, index) in getProofShare"
                      :key="index"
                      class="state my-2"
                      :href="link.url"
                    >
                      {{ link.url }}

                      <IconLink class="" />
                    </a> -->
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss">
.verify {
  & > div.container{
    max-width: 1200px;
  }
  .header {
    .title_header {
      text-align: left;
    }
  }
  .status-verify {
    height: 6px;
    width: 6px;
    font-size: 6px;
    color: #00e284;
  }
  .disable-link {
    pointer-events: none;
    color: #d8d8d8 !important;
    .status-verify {
      color: #d8d8d8 !important;
    }
    svg {
      g {
        fill: #d8d8d8;
      }
    }
  }
  .back-card.v-card {
    border-radius: 14px;
    background-color: transparent;
    box-shadow: none !important;
    margin: 0 auto;

    .more-info {
      .v-card {
        opacity: 0;
      }
      .over-top {
        position: absolute;
      }
      .state {
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: var(--charcoal-grey);
        display: block;
        text-transform: none;
        text-decoration: none;
        svg {
          margin-bottom: -2px;
        }

        span {
          word-break: break-all;
          font-weight: normal;
        }
      }

      .revoked {
        color: var(--coral);
      }
    }
  }

  .back-card.v-card {
    max-width: 874px;

    .back-card.v-card {
      .more-info {
        .state {
          font-size: 13px;
        }
      }
    }
  }
  .slide {
    .header.col-12 {
      .title_header {
        text-align: left !important;
      }
    }
    &.back-card {
      .more-info {
        .v-card {
          -webkit-animation-name: appear;
          animation-name: appear;
          -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          animation-delay: 1.5s;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .more-info {
      opacity: 0;
      border-radius: 14px;
      .over-top {
        max-height: 36px;
        max-width: 36px;
        top: -48px;
        left: calc(50% - 18px);
      }
    }
    .slide {
      .header.col-12 {
        .title_header {
          text-align: left !important;
        }
      }
      &.back-card {
        .more-info {
          -webkit-animation-name: appear;
          animation-name: appear;
          -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          animation-delay: 1.5s;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .back-card.v-card {
      max-width: 874px;
      padding-right: 32px;

      .more-info {
        .state {
          font-size: 13px;
        }
        .over-top {
          margin-left: -40px;
          margin-top: -70px;
        }
      }
    }
    .slide {
      .header.col-12 {
        -webkit-animation-name: slideHeader;
        animation-name: slideHeader;
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        // animation-iteration-count: infinite;

        .title_header {
          text-align: left !important;
        }
      }
      .id-card {
        -webkit-animation-name: slide;
        animation-name: slide;
        -webkit-animation-duration: 1.2s;
        animation-duration: 1.2s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        // animation-delay: 1s;
        // animation-iteration-count: infinite;
      }
      &.back-card {
        -webkit-animation-name: shade;
        animation-name: shade;
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        // animation-delay: 1s;

        // animation-iteration-count: infinite;
        .more-info {
          .v-card {
            -webkit-animation-name: appear;
            animation-name: appear;
            -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            animation-delay: 1.5s;
          }

          // animation-iteration-count: infinite;
        }
      }
    }
  }
}
@keyframes shade {
  0% {
    margin-left: 180px;
    background-color: transparent;
  }
  100% {
    margin-left: 270px;
    background-color: white;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
    background-color: transparent;
  }
  100% {
    opacity: 1;
    background-color: white;
  }
}

@keyframes slideHeader {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -45%;
  }
}
@keyframes slide {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left:-100%;
  }
}
</style>
